import "./scss/style.scss";

import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import axios from "axios";
import VueAxios from "vue-axios";
import VueNativeNotification from "vue-native-notification";
Vue.use(VueAxios, axios);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import Toast from "vue2-toast";
Vue.use(Toast, {
  type: "bottom",
  duration: 7500,
  wordWrap: true,
  width: "auto",
});

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
});

// Vue.notification.requestPermission();

// 30 day after, expire
Vue.$cookies.config("9999d");

import VueUploadComponent from "vue-upload-component";
Vue.component("file-upload", VueUploadComponent);

if (Vue.$cookies.get("version") === null) {
  Vue.$cookies.set("version", "live");
}

if (Vue.$cookies.get("brand") === null) {
  Vue.$cookies.set("brand", "kvalitetsfisk");
}

Vue.use(require("vue-moment"));

let config = {};

// Get a Firestore based on app brand and state
if (
  window.location.hostname.includes("fiskerikajen") ||
  (window.location.hostname.includes("localhost") &&
    Vue.$cookies.get("brand") === "fiskerikajen")
) {
  if (Vue.$cookies.get("version") === "dev") {
    config = {
      apiKey: "AIzaSyDTE3PPr3Pdj0y8YqEKCdvtmQZeIr_xkZs",
      authDomain: "fiskerikajen-dev.firebaseapp.com",
      projectId: "fiskerikajen-dev",
      storageBucket: "fiskerikajen-dev.appspot.com",
      messagingSenderId: "673608090542",
      appId: "1:673608090542:web:e1585f4fce05ca3e6f7e3f",
      measurementId: "G-NVVN0HTW9B",
    };
  } else if (Vue.$cookies.get("version") === "live") {
    config = {
      apiKey: "AIzaSyD8PMGP_15ipBTD6WHjq1hZMSGkNNN8S9E",
      authDomain: "fiskerikajen-f9933.firebaseapp.com",
      projectId: "fiskerikajen-f9933",
      storageBucket: "fiskerikajen-f9933.appspot.com",
      messagingSenderId: "445034860946",
      appId: "1:445034860946:web:a5817c8b5b80dc3e7a714b",
      measurementId: "G-2L24DNCV0T",
    };
  }
} else if (
  window.location.hostname.includes("kvalitetsfisk") ||
  (window.location.hostname.includes("localhost") &&
    Vue.$cookies.get("brand") === "kvalitetsfisk")
) {
  if (Vue.$cookies.get("version") === "dev") {
    config = {
      apiKey: "AIzaSyA509ua08uw_54j4sIEqCeWknH-rBAdcvA",
      authDomain: "kvalitetsfisk-dev.firebaseapp.com",
      projectId: "kvalitetsfisk-dev",
      storageBucket: "kvalitetsfisk-dev.appspot.com",
      messagingSenderId: "1044184841102",
      appId: "1:1044184841102:web:a95e246a3318c717b742df",
      measurementId: "G-LVYH44EBP7",
    };
  } else if (Vue.$cookies.get("version") === "live") {
    config = {
      apiKey: "AIzaSyAUVr2c2ZsygaLGUmXcfoZy4rgmtlDdvh0",
      authDomain: "kvalitetsfisk-app.firebaseapp.com",
      projectId: "kvalitetsfisk-app",
      storageBucket: "kvalitetsfisk-app.appspot.com",
      messagingSenderId: "340475522256",
      appId: "1:340475522256:web:43b69cc3764cb28d07c2d5",
      measurementId: "G-PZZEBSET8G",
    };
  }
} else if (
  window.location.hostname.includes("fiskidag") ||
  (window.location.hostname.includes("localhost") &&
    Vue.$cookies.get("brand") === "fiskidag")
) {
  if (Vue.$cookies.get("version") === "dev") {
    config = {
      apiKey: "AIzaSyCqztmlGvASFMFcnDULRMRNaZhBVL-rx9k",
      authDomain: "fiskidag-dev.firebaseapp.com",
      projectId: "fiskidag-dev",
      storageBucket: "fiskidag-dev.appspot.com",
      messagingSenderId: "17857990314",
      appId: "1:17857990314:web:f23178ac2058c9b15d8ad3",
      measurementId: "G-7K8W3K6S1Y",
    };
  } else if (Vue.$cookies.get("version") === "live") {
    config = {
      apiKey: "AIzaSyDT72zo3eIrRNoSLa-6utlGBQUTGgCNocw",
      authDomain: "fiskidag-app.firebaseapp.com",
      projectId: "fiskidag-app",
      storageBucket: "fiskidag-app.appspot.com",
      messagingSenderId: "672040144021",
      appId: "1:672040144021:web:429d1bea991090dc6c1f9c",
      measurementId: "G-S0Q010WRS5",
    };
  }
} else if (
  window.location.hostname.includes("fsgfoods") ||
  window.location.hostname.includes("flyingseafood") ||
  (window.location.hostname.includes("localhost") &&
    Vue.$cookies.get("brand") === "fsg")
) {
  if (Vue.$cookies.get("version") === "dev") {
    config = {
      apiKey: "AIzaSyA6s8sJ7K-WSlIaDVEeZIjMgdpok40DVUg",
      authDomain: "flyingseafood-dev.firebaseapp.com",
      projectId: "flyingseafood-dev",
      storageBucket: "flyingseafood-dev.appspot.com",
      messagingSenderId: "644017794098",
      appId: "1:644017794098:web:26e401b98fa14c3823202b",
      measurementId: "G-ZYZBBQVW5M",
    };
  } else if (Vue.$cookies.get("version") === "live") {
    config = {
      apiKey: "AIzaSyDHGAGi6GCnDycD0_tlqD80Txbzey6d-kA",
      authDomain: "flyingseafood-app.firebaseapp.com",
      projectId: "flyingseafood-app",
      storageBucket: "flyingseafood-app.appspot.com",
      messagingSenderId: "68351789730",
      appId: "1:68351789730:web:97c90569ab3ca89c90a5fb",
      measurementId: "G-WJK0L71V80",
    };
  }
}

firebase.initializeApp(config);
// firebase
//   .firestore()
//   .settings({ experimentalForceLongPolling: true, merge: true });
export const db = firebase.firestore();

import App from "./App.vue";
import router from "./router";
import store from "./store";

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    if (!store.state.settings.init) {
      store.dispatch("settings/initApp", user.uid).then(() => {
        // Only admins in adminpanel
        if (
          store.state.settings.user.role !== "admin" &&
          store.state.settings.user.role !== "superAdmin"
        ) {
          firebase.auth().signOut();
        }

        // If this came form login page
        if (router.currentRoute.name === "Login") {
          router.push("/home");
        }
      });
    }
  } else {
    // User logged out/not logged in yet

    if (store.state.settings.init) {
      store.dispatch("settings/clearApp");
    }

    if (router.currentRoute.name !== "Login") {
      router.push("/login");
    }
  }
});

Vue.config.productionTip = false;
Vue.component("Sidebar", require("@/components/Sidebar.vue").default);
Vue.component(
  "PostsFilterSelections",
  require("@/components/PostsFilterSelections.vue").default
);
Vue.component(
  "ChatFilterSelections",
  require("@/components/ChatFilterSelections.vue").default
);
Vue.component(
  "Maintenance",
  require("@/components/settings/Maintenance.vue").default
);
Vue.component(
  "PostsSelectionBoxes",
  require("@/components/PostsSelectionBoxes.vue").default
);
Vue.component(
  "PostsSelectionFinalCompanies",
  require("@/components/PostsSelectionFinalCompanies.vue").default
);
Vue.component(
  "PostsSelectionFinalUsers",
  require("@/components/PostsSelectionFinalUsers.vue").default
);
Vue.component("PostsListing", require("@/components/PostsListing.vue").default);
Vue.component(
  "PostsTemplateHandler",
  require("@/components/PostsTemplateHandler.vue").default
);
Vue.component(
  "SharedShortlistsSelector",
  require("@/components/shared-shortlists/SharedShortlistsSelector.vue").default
);
Vue.component(
  "SharedShortlistsHeader",
  require("@/components/shared-shortlists/SharedShortlistsHeader.vue").default
);
Vue.component(
  "SharedShortlistsFooter",
  require("@/components/shared-shortlists/SharedShortlistsFooter.vue").default
);
Vue.component(
  "SharedShortlistsCompanySelection",
  require("@/components/shared-shortlists/SharedShortlistsCompanySelection.vue")
    .default
);
Vue.component(
  "SharedShortlistsProductSelection",
  require("@/components/shared-shortlists/SharedShortlistsProductSelection.vue")
    .default
);
Vue.component(
  "SharedShortlistsFinalCompanies",
  require("@/components/shared-shortlists/SharedShortlistsFinalCompanies.vue")
    .default
);
Vue.component(
  "CompanyShortlistsSelector",
  require("@/components/company-shortlists/CompanyShortlistsSelector.vue")
    .default
);
Vue.component(
  "CompanyShortlistsHeader",
  require("@/components/company-shortlists/CompanyShortlistsHeader.vue").default
);
Vue.component(
  "CompanyShortlistsFooter",
  require("@/components/company-shortlists/CompanyShortlistsFooter.vue").default
);
Vue.component(
  "CompanyShortlistsProductSelection",
  require("@/components/company-shortlists/CompanyShortlistsProductSelection.vue")
    .default
);
Vue.component(
  "CompanyShortlistsCompanySelection",
  require("@/components/company-shortlists/CompanyShortlistsCompanySelection.vue")
    .default
);
Vue.component(
  "UnavailableDates",
  require("@/components/settings/UnavailableDates.vue").default
);
Vue.component(
  "NoCreditMessage",
  require("@/components/NoCreditMessage.vue").default
);

Vue.component(
  "SingleCompanyProducts",
  require("@/components/single-company/SingleCompanyProducts.vue").default
);

Vue.component(
  "DeliveryCutOff",
  require("@/components/settings/DeliveryCutOff.vue").default
);

Vue.component(
  "DeliveryDateSettings",
  require("@/components/settings/DeliveryDateSettings.vue").default
);

Vue.component(
  "CurrencySettings",
  require("@/components/settings/CurrencySettings.vue").default
);

Vue.component(
  "ProductImageToggle",
  require("@/components/settings/ProductImageToggle.vue").default
);
Vue.component(
  "LanguagesAddNewPopup",
  require("@/components/languages/LanguagesAddNewPopup.vue").default
);
Vue.component(
  "LanguagesSelector",
  require("@/components/languages/LanguagesSelector.vue").default
);
Vue.component(
  "LanguagesFields",
  require("@/components/languages/LanguagesFields.vue").default
);
Vue.component("CartUpsell", require("@/views/CartUpsell.vue").default);

Vue.component(
  "UpsellListSelector",
  require("@/components/cart-upsell/UpsellListSelector.vue").default
);

Vue.component(
  "UpsellListHeader",
  require("@/components/cart-upsell/UpsellListHeader.vue").default
);

Vue.component(
  "UpsellCompanies",
  require("@/components/cart-upsell/UpsellCompanies.vue").default
);

Vue.component(
  "UpsellProductSelection",
  require("@/components/cart-upsell/UpsellProductSelection.vue").default
);

Vue.component(
  "UpsellCurrentListCompanies",
  require("@/components/cart-upsell/UpsellCurrentListCompanies.vue").default
);

Vue.component(
  "UpsellHomeSegments",
  require("@/components/cart-upsell/UpsellHomeSegments.vue").default
);

Vue.component(
  "UpsellEditFooter",
  require("@/components/cart-upsell/UpsellEditFooter.vue").default
);

Vue.component(
  "UpsellSetListsConfirm",
  require("@/components/cart-upsell/UpsellSetListsConfirm.vue").default
);

Vue.component(
  "UpsellCompanyFilters",
  require("@/components/cart-upsell/UpsellCompanyFilters.vue").default
);

Vue.component(
  "ChatWindow",
  require("@/components/chat/ChatWindow.vue").default
);

Vue.component(
  "ChatRoomSelector",
  require("@/components/chat/ChatRoomSelector.vue").default
);

Vue.component(
  "ChatAdminSelector",
  require("@/components/chat/ChatAdminSelector.vue").default
);

Vue.component(
  "ChatLightbox",
  require("@/components/chat/ChatLightbox.vue").default
);

Vue.component(
  "ChatSearchFilter",
  require("@/components/chat/ChatSearchFilter.vue").default
);

Vue.component(
  "ChatProductCard",
  require("@/components/chat/ChatProductCard.vue").default
);

Vue.component(
  "ChatMassMessageSelector",
  require("@/components/chat/ChatMassMessageSelector.vue").default
);

store.dispatch("settings/bindAppSettings").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});

Vue.component(
  "WebPagesList",
  require("@/components/web-pages/WebPagesList.vue").default
);

Vue.component(
  "WebPagesEditor",
  require("@/components/web-pages/WebPagesEditor.vue").default
);

Vue.component(
  "WebPagesEditorActions",
  require("@/components/web-pages/WebPagesEditorActions.vue").default
);

Vue.component(
  "WebPagesEditorForm",
  require("@/components/web-pages/WebPagesEditorForm.vue").default
);

Vue.component(
  "WebPagesBuilder",
  require("@/components/web-pages/WebPagesBuilder.vue").default
);

Vue.component(
  "WebPagesBlockSelector",
  require("@/components/web-pages/WebPagesBlockSelector.vue").default
);

Vue.component(
  "WebPagesCurrentBlocks",
  require("@/components/web-pages/WebPagesCurrentBlocks.vue").default
);

Vue.component(
  "WebPagesHeroBlockForm",
  require("@/components/web-pages/WebPagesHeroBlockForm.vue").default
);

Vue.component(
  "WebPagesUpsellBlockForm",
  require("@/components/web-pages/WebPagesUpsellBlockForm.vue").default
);

Vue.component(
  "WebPagesNewsBlockForm",
  require("@/components/web-pages/WebPagesNewsBlockForm.vue").default
);

Vue.component(
  "WebPagesContentBoxForm",
  require("@/components/web-pages/WebPagesContentBoxForm.vue").default
);

Vue.component(
  "WebPagesTwoContentBoxesForm",
  require("@/components/web-pages/WebPagesTwoContentBoxesForm.vue").default
);

Vue.component(
  "WebPagesCoworkersBlockForm",
  require("@/components/web-pages/WebPagesCoworkersBlockForm.vue").default
);

Vue.component(
  "WebPagesMenuEditor",
  require("@/components/web-pages/WebPagesMenuEditor.vue").default
);

Vue.component(
  "RecurringOrderEditor",
  require("@/components/recurring-orders/RecurringOrderEditor.vue").default
);

Vue.component(
  "RecurringOrderCompanySelector",
  require("@/components/recurring-orders/RecurringOrderCompanySelector.vue")
    .default
);

Vue.component(
  "RecurringOrderDaySelector",
  require("@/components/recurring-orders/RecurringOrderDaySelector.vue").default
);

Vue.component(
  "RecurringOrderProductSelector",
  require("@/components/recurring-orders/RecurringOrderProductSelector.vue")
    .default
);

Vue.component(
  "RecurringOrderActions",
  require("@/components/recurring-orders/RecurringOrderActions.vue").default
);

Vue.component(
  "WebPagesTitleForm",
  require("@/components/web-pages/WebPagesTitleForm.vue").default
);

Vue.component(
  "WebPagesTextForm",
  require("@/components/web-pages/WebPagesTextForm.vue").default
);

Vue.component(
  "WebPagesProductsForm",
  require("@/components/web-pages/WebPagesProductsForm.vue").default
);

Vue.component(
  "WebPagesImageForm",
  require("@/components/web-pages/WebPagesImageForm.vue").default
);

Vue.component(
  "WebPagesButtonForm",
  require("@/components/web-pages/WebPagesButtonForm.vue").default
);
